@import "../../general/vars";
$imageHeight: 450px;
.customCardMedia{
  height: 100%;
  min-height: $imageHeight;
  width: 100% !important;
  @media (min-width: 300px) and (max-width: 660px) {
    min-height: $imageHeight;
  }
}
.oneService{
  width: 100%;
  background-color: #141414!important;
  border: none;
  text-align: justify;
  .service-header{
    background-color: $primary;
    transition: background-color 200ms, color 200ms;
  }
  .customCardMedia{
    filter: grayscale(15%);
  }
  &:hover{
    .customCardMedia {
      filter: contrast(102%) grayscale(0);
    }
    .service-header{
      background-color: darken($secondary, 10);
      //color: $secondary
    }
  }
  .overtext{
    position: absolute;
    background-color: rgba(0,0,0,0.75);
    padding: 1rem;
    color: white;
    width: 100%;
    height: 100%;
    min-height: $imageHeight;
    display:-webkit-flex;
    display:-webkit-box;
    display:-moz-flex;
    display:-moz-box;
    display:-ms-flexbox;
    display: flex;
    justify-content: center;
    flex-direction: column;
    p{
      background-color: transparent;
      position: relative;
      display: block;
      margin-left: 15px;
      margin-bottom: 10px;
      &:before{
        display: block;
        content: '';
        width: 5px;
        height: 5px;
        line-height: 5px;
        border-radius: 50%;
        background-color: white;
        position: absolute;
        top: 7.5px;
        left: -15px;
      }
    }
    @media (min-width: 300px) and (max-width: 660px) {
      min-height: $imageHeight;
    }
  }
}
.disableCursor{
  &:hover{
    cursor: default;
  }
}