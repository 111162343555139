@import "general/vars";
.bg-img-container{
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  @media (max-width: 800px) {
    background-position: 70%;
    min-height: 70vh;
  }
}

.text-white{
  color: white;
}
.text-lightgrey{
  color: #b3b3b3;
}
.text-center{
  text-align: center;
}
.word-one-line{
  word-spacing: 100vw;
}

.fw{
  width: 100%!important;
}
a{
  text-decoration: none;
  font-weight: 500;
  color: inherit;
&:hover{
   text-decoration: underline;
 }
}

$step: 1;
@for $i from 1 through 60 {
$attC: $i * $step;
  .pt-#{$attC} { padding-top: $attC/14 +em}
  .pb-#{$attC} { padding-bottom: $attC/14 +em}
  .pr-#{$attC} { padding-right: $attC/14 +em}
  .pl-#{$attC} { padding-left: $attC/14 +em}
  .p-#{$attC}  { padding: $attC/14 +em}

  .mt-#{$attC} { margin-top: $attC/14 + rem !important}
  .mb-#{$attC} { margin-bottom: $attC/14 +rem !important}
  .mr-#{$attC} { margin-right: $attC/14 +rem !important}
  .ml-#{$attC} { margin-left: $attC/14 +rem !important}
  .m-#{$attC}  { margin: $attC/14 +rem !important}
}
$step: 1;
@for $i from 1 through 50 {
$attC: $i * $step;
  .pt-px-#{$attC} { padding-top: $attC + px}
  .pb-px-#{$attC} { padding-bottom: $attC + px}
  .pr-px-#{$attC} { padding-right: $attC + px}
  .pl-px-#{$attC} { padding-left: $attC + px}
  .p-px-#{$attC}  { padding: $attC + px}

  .mt-px-#{$attC} { margin-top: $attC + px !important}
  .mb-px-#{$attC} { margin-bottom: $attC + px !important}
  .mr-px-#{$attC} { margin-right: $attC + px !important}
  .ml-px-#{$attC} { margin-left: $attC + px !important}
  .m-px-#{$attC}  { margin: $attC + px !important}
}
@for $i from 12 through 90 {
  .fs-#{$i}{
    font-size: $i/14 + rem!important;
    @media (max-width: 1200px) {
      font-size: ($i/14)*0.9 + rem!important;
    }
    @media (max-width: 780px) {
      font-size: ($i/14)*0.75 + rem!important;
    }
    @media (max-width: 660px) {
      font-size: ($i/14)*0.6 + rem!important;
    }
    @media (max-width: 530px) {
      font-size: ($i/14)*0.5 + rem!important;
    }
    @media (max-width: 450px) {
      font-size: ($i/14)*0.38 + rem!important;
    }
    @media (max-width: 350px) {
      font-size: ($i/14)*0.32 + rem!important;
    }
  }
  .fs-min-#{$i}{
    @media (min-width: 300px) and (max-width: 780px) {
      font-size: ($i/14) + rem!important;
    }
  }
}

@for $i from 1 through 14 {
  .mw-#{$i}{
    max-width: $i*100 + px;
    width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
@for $i from 1 through 9 {
  .opacity-#{$i}{
    opacity: $i/10;
  }
}
@for $i from 1 through 15 {
  .ls-#{$i}{
    letter-spacing: $i/14+em;
  }
}

.bg-half-secondary{
  position: absolute;
  top:0;
  width: 100%;
  background-color: $secondary;
  z-index: 1;
  min-height: 20rem;
}
.bg-full-secondary{
  width: 100%;
  background-color: $secondary;
}
#root{
  overflow: hidden;
}
.fw-weight-500{
  font-weight: 500 !important;
}
.fw-weight-600{
  font-weight: 600 !important;
}