@import "../../general/vars";

.oneLang{
  color: $primary;
  &:hover{
    cursor: pointer;
  }
  &.activeLang{
    color: white;
    font-weight: bold;
  }
}