@import "../../general/vars";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
.contact-info{
  text-align: right;
  @media (max-width: 650px) {
    &:first-child{
      margin-top: 20/14+em!important;
    }
    text-align: left;
  }
}
.gold-color{
  color: rgb(213,177,112);
}
.bg-gold-color{
  background-color: rgb(213,177,112);
}
.MuiButton-contained:hover{
  background-color: rgb(213,177,112)!important;
}
.max-w-800{
  max-width: 800px;
  width: 100%;
}
.bgtnimage{
  background-attachment: fixed;
  background-position: center;
  background-color: rgb(66,47,41);
}
.front{
  z-index: 3;
}
.ftext{
  max-width: 470px;
  float: right;
  @media (max-width: 650px){
    float: left;
  }
}